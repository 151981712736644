@import url('https://fonts.googleapis.com/css?family=Roboto:100&display=block');

@import '../variables.module.scss';

html,
body,
#root {
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
}

.hex-time {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .hex {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 17.5vmin;
    letter-spacing: 1vmin;
    pointer-events: none;
    user-select: none;
    margin: auto;
    color: $white;
  }
  .repo {
    opacity: 0.125;
    text-decoration: none;
    padding: 0.5rem;
    word-break: break-all;
    text-align: center;
    font-size: 0.85rem;
    transition: all 0.5s;
    &:hover {
      opacity: 0.25;
    }
  }
}
